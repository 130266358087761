import styled from "styled-components";
import Login from "./utils/login"
import { useEffect,useState} from 'react';
import { ERROR_MESSAGE} from "../../components/Error"
const AdminLoginForm = styled.form`
    margin-top:4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    input {
      width: 20rem;
      height: 1rem;
      padding: 20px;
      outline: none;
      margin:5px;
    }
    input[type="submit"] {
      margin: 20px;
      padding: 0;
      height: 40px;
      width: 300px;
    }
    label {
      text-transform: uppercase;
      text-align: center;
    }
    input
    {
      text-align: center;
    }
    select {
      width: 22.5rem;
      height: 4rem;
      padding: 18px;
      margin: 5px;
    }
    p,h1
    {
      margin: 2px;
    }

    `;



export default function AdminForm(props:any) {
  const [error, seterror] = useState<any>(false);
  const [error_message, seterror_message] = useState<any>("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      seterror(false);
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error]);


  return (
   <AdminLoginForm onSubmit={Handleform}>
    {error ? <ERROR_MESSAGE error_message={error_message} /> : <></>}
    <h1>Se connecter</h1>
    <p>Espace dédié aux agents de la mairie de crosne</p>
    <input type="text" name="email"placeholder="Nom d'utilisateur ou Email"></input>
    <input type="password" name="password" placeholder="Votre mot de passe"/>
    <input type="submit" value="Se connecter"/>
  </AdminLoginForm>);



function Handleform(e:any)
{
    e.preventDefault();
    var email = (e.currentTarget.elements.namedItem('email') as HTMLInputElement).value;
    var password = (e.currentTarget.elements.namedItem('password') as HTMLInputElement).value;
    Login(email,password,(uuid:any,token:any)=>
    {
      if(uuid && token)
      {
        localStorage.setItem("admin_uuid", uuid);
        localStorage.setItem("admin_token", token);
        props.onChange(true);
      }
      else
      {
        seterror(true);
        seterror_message("Erreur de connexion");
      }
    });
}
}