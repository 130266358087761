import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark} from '@fortawesome/free-solid-svg-icons'
const ERROR_MESSAGE1 = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    
    .error_wrapper
    {
      h1
      {
        font-size: 20px;
      }
      margin:1rem;
      padding : 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      text-align: center;
      background-color: #DC143C ;

      border-radius: 25px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;  

      color: #D8000C;
      background-color: #FFBABA;
      margin: 10px 0;
      padding: 10px;
      // border-radius: 3px 3px 3px 3px;
      
    }
    `;
function ERROR_MESSAGE({error_message}:{error_message:string})
{
    return(<ERROR_MESSAGE1><div className="error_wrapper"> <FontAwesomeIcon icon={faCircleXmark}/><h1>{error_message}</h1></div></ERROR_MESSAGE1  >);
}


export { ERROR_MESSAGE}