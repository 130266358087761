export default function Profile() {
  return (
    <div>
      <center>
        <h1>Page de modification de profil prochainement disponible</h1>
      </center>
      {/* <form>
        <label htmlFor="nom">Nom</label>
        <input type="text" name="nom" />
      </form> */}
    </div>
  );
}
