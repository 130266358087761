import styled from "styled-components";
import { useLottie } from "lottie-react";
import done from "../../../assets/lootie/done_1.json";
const Dive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  h2.email_send_title {
    text-align: center;
    padding-top: 40px;
  }
`;
const Done = () => {
  const options = {
    animationData: done,
    loop: false,
  };
  const { View } = useLottie(options);

  return (
    <Dive className="yo">
      {View}
      <h2 className="email_send_title">
        Merci <br />
        Votre rendez-vous est confirmé
        <br /> Vous allez recevoir un email de confirmation
      </h2>
      <p>Pensez à verifier vos spams</p>
    </Dive>
  );
};

export default Done;
