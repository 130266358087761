import styled from "styled-components";
import { useLottie } from "lottie-react";
import done from "../../../assets/lootie/contact_email.json";

const Dive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4rem;
  h2.email_send_title {
    text-align: center;
  }
`;
const Done = () => {
  const options = {
    animationData: done,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <Dive className="yo">
      {View}
      <h2 className="email_send_title">
        Nous avons bien reçu votre message,
        <br />
        il sera traité dans les plus brefs délais !
      </h2>
    </Dive>
  );
};

export default Done;
