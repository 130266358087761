import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("admin_uuid", "");
    localStorage.setItem("admin_token", "");
    navigate("/croadmin");
  }, [navigate]);

  return (
    <div>
      <center>
        <h1>Page de déconnexion</h1>
      </center>
    </div>
  );
}
