import styled from "styled-components";
import { useState, useRef } from "react";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Contact_form } from "./contact";
import EMAIL_SEND from "../../components/animation/Email_send";

const Form = styled.form`
  min-height: calc(100vw-attr(height));
  display: flex;
  flex-direction: column;
  align-items: center;
  input,
  input[type="submit"] {
    outline: none;
    margin: 5px;
    width: 30%;
    height: 40px;
    text-align: center;
    border: solid 0.1px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  }
  textarea {
    outline: none;
    margin: 5px;
    width: 30%;
    text-align: center;
    border: solid 0.1px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  }
  h2 {
    margin: 10px;
  }
`;
function Contact() {
  const [token, setToken]: any = useState(null);
  const captchaRef: any = useRef(null);
  const [done, setDone]: any = useState(false);
  return (
    <>
      {done ? (
        <EMAIL_SEND />
      ) : (
        <Form onSubmit={Handleform}>
          <h2>CONTACT</h2>
          <input type="text" placeholder="Nom" name="nom" />
          <input type="text" placeholder="Prénom" name="prenom" />
          <input type="email" placeholder="Email" name="email" />
          <textarea placeholder="Message" rows={5} name="message" />
          <HCaptcha
            sitekey="b56a69cb-a96d-46b6-87ea-2085da8e948b"
            onVerify={setToken}
            ref={captchaRef}
          />
          <input type="submit" value="Envoyer" />
        </Form>
      )}
    </>
  );

  function Handleform(e: any) {
    e.preventDefault();
    var nom = (e.currentTarget.elements.namedItem("nom") as HTMLInputElement)
      .value;
    var prenom = (
      e.currentTarget.elements.namedItem("prenom") as HTMLInputElement
    ).value;
    var email = (
      e.currentTarget.elements.namedItem("email") as HTMLInputElement
    ).value;
    var message = (
      e.currentTarget.elements.namedItem("message") as HTMLInputElement
    ).value;
    if (
      !token ||
      !nom ||
      !prenom ||
      !email ||
      !message ||
      token === "" ||
      nom === "" ||
      prenom === "" ||
      message === ""
    ) {
      alert("merci de bien vouloir remplir les champs");
    } else if (token && nom && prenom && email && message) {
      Contact_form(email, nom, prenom, message, token).then((data: any) => {
        console.log(data);
        if (data.success) {
          setDone(true);
          console.log("email envoyé");
        } else {
        }
      });
    }
  }
}
export default Contact;
