import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Load_rdv, Load_rdv_today } from "./load_rdv";
import jwtDecode from "jwt-decode";
import config from "../../../config";
import { block_creaneau, block_creaneau_multi } from "./api";
import {
  faCircleXmark,
  faHourglassStart,
  faCircleCheck,
  faQuestion,
  faBan,
  faSquarePen,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { edit_rdv } from "./edit_rdv";

const DASH_TABLE = styled.div`
  z-index: 0;
  .header {
    background-color: #3c6cb3;
    color: white;
    font-size: 1.5em;
    padding: 1rem;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  .table-users {
    border: 1px solid #327a81;
    border-radius: 10px;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
    max-width: calc(100% - 2em);
    margin: 1em auto;
    overflow: hidden;
    width: 1000px;
  }

  .row {
    display: flex;
    border-bottom: 1px solid #327a81;
    position: relative;
  }

  .header-row {
    font-weight: bold;
  }

  .column {
    flex: 1;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: 40px;
    height: 40px;
  }
  .state-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .pending {
    background-color: yellow;
  }

  .done {
    background-color: green;
  }

  .canceled {
    background-color: red;
  }

  .rip {
    background-color: gray;
  }

  .action-icon {
    width: 20px;
    height: 20px;
  }
  .bloquer {
    margin: 5px;
    margin-top: 20px;
    padding: 10px 40px;
    background-color: #3c6cb3;
    color: white;
    border: none;
    border-radius: 20px;
  }
  .creneau_lock_container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px); /* Apply a blur effect to the background */
    z-index: 9999; /* Set a high z-index to ensure the element is on top */
    overflow: scroll;
    background-overflow: visible;
  }
  div.creneau_lock_container.active {
    display: flex !important;
  }
  .creneau_lock_container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
      0,
      0,
      0,
      0.5
    ); /* Adjust the alpha value to control darkness */
    z-index: -1; /* Place the pseudo-element behind the container */
  }

  .creneau_lock {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
  }
  .creneau_lock form {
    margin: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .creneau_lock input,
  label,
  select {
    padding: 10px 50px;
    margin: 5px;
  }
  .close_btn {
    display: flex;
    flex-direction: column;
  }
  .close_btn_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
  }
  input {
    text-align: center;
  }
  .red-row {
    background-color: #ffb7b5;
  }
`;

export default function Dashboard() {
  const navigate = useNavigate();
  const [blockCreneau, SetblockCreneau] = useState<any>();
  const [blockCreneau1, SetblockCreneau1] = useState<any>();
  const [editform, Seteditform] = useState<any>();
  const [editform_rdv, Seteditform_rdv] = useState<any>();
  const [creneaux, setCreneaux] = useState<Array<any>>([]);
  const [rdv_today, setrdvtoday] = useState(false);
  const [date, set_date] = useState<any>();
  useEffect(() => {
    async function Loadcreneaux() {
      var date = editform_rdv ? editform_rdv.date : undefined;
      console.log(config.url);
      if (date && date.length > 0) {
        var localvardate1 = date.match(
          /(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/
        );
        var final_date =
          localvardate1[3] + "/" + localvardate1[2] + "/" + localvardate1[1];
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ jour: final_date }),
        };
        await fetch(
          config.url + "/api/rendez-vous/get_creneau_single",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("loading rdv");
            if (data.error || data.creneaux.length <= 0) {
              setCreneaux([]);
            } else if (data.creneaux) {
              setCreneaux(data.creneaux);
            } else {
              setCreneaux([]);
            }
          });
      }
    }
    Loadcreneaux();
  }, [editform_rdv]);
  useEffect(() => {
    async function Loadcreneaux() {
      console.log(config.url);
      if (date && date.length > 0) {
        let startTime = performance.now();
        var localvardate1 = date.match(
          /(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])/
        );
        var final_date =
          localvardate1[3] + "/" + localvardate1[2] + "/" + localvardate1[1];
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ jour: final_date }),
        };
        await fetch(
          config.url + "/api/rendez-vous/get_creneau_single",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            console.log("loading rdv");
            if (data.error || data.creneaux.length <= 0) {
              setCreneaux([]);
            } else if (data.creneaux) {
              console.log("ici data creneaux " + data.creneaux);
              setCreneaux(data.creneaux);
            } else {
              setCreneaux([]);
              console.log("pas de date fourni");
            }
            let endTime = performance.now(); // End time in milliseconds
            let executionTime = endTime - startTime; // Execution time in milliseconds
            console.log(executionTime);
            if (executionTime < 2000) {
              console.log(true);
              setTimeout(() => {
                console.log("dans le timeout");
              }, 2000);
            } else {
              console.log(false);
            }
          });
      }
    }
    Loadcreneaux();
  }, [date]);
  // useEffect(() => {
  //   console.log(editform_rdv);
  // }, [editform_rdv]);
  useEffect(() => {
    const admin_uuid = localStorage.getItem("admin_uuid");
    const admin_token = localStorage.getItem("admin_token");
    const decodedToken: any = admin_token && jwtDecode(admin_token);
    // Get the expiration time from the decoded token
    const expirationTime = admin_token && decodedToken.exp;

    // Get the current timestamp
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (expirationTime < currentTimestamp) {
      localStorage.removeItem("admin_uuid");
      localStorage.removeItem("admin_token");
    }

    if (
      !admin_uuid ||
      !admin_token ||
      admin_uuid === "" ||
      admin_token === "" ||
      admin_uuid === null ||
      admin_token === null ||
      admin_uuid === undefined ||
      admin_token === undefined
    ) {
      navigate("/croadmin");
    }
  }, [navigate]);

  const handleToggleCreneau = () => {
    SetblockCreneau(!blockCreneau);
  };
  const handleToggleCreneau1 = () => {
    SetblockCreneau1(!blockCreneau1);
  };
  const handleToggleEditform = () => {
    Seteditform(!editform);
    if (editform) {
      Seteditform_rdv("");
    }
  };

  return (
    <>
      <DASH_TABLE>
        <center>
          <button
            className="bloquer"
            // onClick={handleToggleCreneau}
            onClick={() =>
              alert("cette fonctionnalité est en cours de développement")
            }
          >
            Bloquer une date
          </button>
          <button className="bloquer" onClick={handleToggleCreneau1}>
            Bloquer des créneaux
          </button>
          <Link to={"/reservation"}>
            <button
              className="bloquer"
              // onClick={() => {
              //   alert("création de RDV Non Disponible");
              // }}
            >
              Créer un RDV
            </button>
          </Link>
        </center>
        <center>
          <button
            className="bloquer"
            onClick={() => {
              setrdvtoday(!rdv_today);
            }}
          >
            Aujourd'hui
          </button>
        </center>
        {/* first form */}
        <div
          className={`creneau_lock_container ${blockCreneau ? "active" : ""}`}
        >
          <div className="creneau_lock">
            <div className="close_btn_wrapper" onClick={handleToggleCreneau}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                color="red"
                title="annulé"
              />
            </div>
            <form onSubmit={Block_date}>
              <label htmlFor="date">
                Quelle date souhaitez-vous bloquer ?{" "}
              </label>
              <input
                type="date"
                name="date"
                min={new Date().toISOString().split("T")[0]}
              />
              <input type="submit" name="Valider" />
            </form>
          </div>
        </div>
        {/* end first form */}
        {/* second form */}
        <div
          className={`creneau_lock_container ${blockCreneau1 ? "active" : ""}`}
        >
          <div className="creneau_lock">
            <div className="close_btn_wrapper" onClick={handleToggleCreneau1}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                color="red"
                title="annulé"
              />
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                const selectElement = e.currentTarget.elements.namedItem(
                  "creneauxmulti"
                ) as HTMLSelectElement;

                // Get the indices of the selected options
                const selectedIndices = Array.from(
                  selectElement.selectedOptions
                ).map((option) => option.index);

                const selectedOptions = (
                  e.currentTarget.elements.namedItem(
                    "creneauxmulti"
                  ) as HTMLSelectElement
                ).selectedOptions;
                var selectedValues = Array.from(selectedOptions).map(
                  (option) => option.value
                );
                const selectedValuesString = selectedValues
                  .join(",")
                  .replace(/\s/g, "");

                const date = (
                  e.currentTarget.elements.namedItem(
                    "date"
                  ) as HTMLSelectElement
                ).value;
                console.log(selectedValuesString);
                block_creaneau_multi(date, selectedValuesString).then(
                  (data: any) => {
                    console.log(data);
                    if (data.error) {
                      alert(data.error);
                    } else if (data.success) {
                      for (let i = selectedIndices.length - 1; i >= 0; i--) {
                        selectElement.remove(selectedIndices[i]);
                      }
                      selectedValues = [];
                      alert(data.success);
                    } else {
                      alert("une erreur imprévue s'est produite");
                    }
                  }
                );
              }}
            >
              <label htmlFor="date">
                Veuillez choisir une date pour bloquer des créneaux.
              </label>
              <input
                type="date"
                name="date"
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  set_date(e.target.value);
                }}
              />
              <label htmlFor="creneauxmulti">
                Quels créneaux souhaitez-vous bloquer ?
              </label>
              <select
                name="creneauxmulti"
                id="creneauxmulti"
                multiple
                disabled={!date}
              >
                <option value="">Veuillez chosir une date</option>
                {date ? <GenTimeList Localcreneaux={creneaux} /> : ""}
              </select>
              <input type="submit" name="Valider" />
            </form>
          </div>
        </div>
        {/* end second form */}
        {/* edit form */}
        <div className={`creneau_lock_container ${editform ? "active" : ""}`}>
          <div className="creneau_lock">
            <div className="close_btn_wrapper" onClick={handleToggleEditform}>
              <FontAwesomeIcon
                icon={faCircleXmark}
                color="red"
                title="annulé"
              />
            </div>

            <form
              // onSubmit={(e) => {
              //   e.preventDefault();
              //   alert("Modification de rendez vous non disponible");
              // }}
              onSubmit={edit_rdv_srv}
            >
              <h2>Modification de rendez-vous</h2>
              <label htmlFor="id_rdv">ID RDV</label>
              <input
                type="text"
                name="id_rdv"
                value={editform_rdv ? editform_rdv.id : "pas_id"}
                disabled
              />
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                value={editform_rdv ? editform_rdv.email : "pas_email"}
                disabled
              />
              <input
                type="text"
                name="tel"
                value={editform_rdv ? editform_rdv.tel : "pas_tel"}
                disabled
              />
              <label htmlFor="date">Nom</label>
              <input
                type="text"
                name="nom"
                value={editform_rdv ? editform_rdv.nom : "pas_id"}
                onChange={(e) => {
                  Seteditform_rdv({ ...editform_rdv, nom: e.target.value });
                }}
                disabled
              />
              <label htmlFor="prenom">Prénom</label>
              <input
                type="text"
                name="prenom"
                value={editform_rdv ? editform_rdv.prenom : "pas_id"}
                onChange={(e) => {
                  Seteditform_rdv({ ...editform_rdv, prenom: e.target.value });
                }}
                disabled
              />
              <label htmlFor="date">Date</label>
              {editform_rdv && (
                <input
                  name="date"
                  type="date"
                  placeholder="Date"
                  min={new Date().toISOString().split("T")[0]}
                  required
                  value={
                    !editform_rdv.hasOwnProperty("date")
                      ? moment
                          .unix(editform_rdv.date_time._seconds)
                          .locale("fr")
                          .format("YYYY-MM-DD")
                      : editform_rdv.date
                  }
                  onChange={(e) => {
                    Seteditform_rdv({ ...editform_rdv, date: e.target.value });
                  }}
                />
              )}
              <label htmlFor="heure">Heure</label>

              <select name="heure">
                {editform_rdv && !editform_rdv.hasOwnProperty("date") ? (
                  <option
                    value={moment
                      .unix(editform_rdv.date_time._seconds)
                      .utc()
                      .locale("fr")
                      .format("HH:mm")}
                  >
                    {moment
                      .unix(editform_rdv.date_time._seconds)
                      .utc()
                      .locale("fr")
                      .format("HH:mm")}
                  </option>
                ) : (
                  <GenTimeList Localcreneaux={creneaux} />
                )}
              </select>
              <label htmlFor="bloquer_ancien_creneau">
                Bloquer l'ancien creneau ?
              </label>
              <input type="checkbox" name="bloquer_ancien_creneau" />
              <input
                type="submit"
                name="Valider"
                disabled={editform_rdv && !editform_rdv.hasOwnProperty("date")}
              />
            </form>
          </div>
        </div>
        {/* edit form */}
        <div className="table-users">
          <div className="header">Vos rendez-vous</div>
          <div className="row header-row">
            <div className="column">NOM</div>
            <div className="column">PRENOM</div>
            <div className="column">DATE</div>
            <div className="column">HEURE</div>
            <div className="column">DEMARCHE</div>
            <div className="column">STATUT</div>
            <div className="column">ANNULER</div>
            <div className="column">MODIFIER</div>

            {/* <div className="column">ACTION</div> */}
          </div>
          {rdv_today ? (
            <GEN_RDV_TODAY
              handleToggleEditform={handleToggleEditform}
              Seteditform_rdv={Seteditform_rdv}
            />
          ) : (
            <GEN_RDV
              handleToggleEditform={handleToggleEditform}
              Seteditform_rdv={Seteditform_rdv}
            />
          )}
        </div>
      </DASH_TABLE>
    </>
  );
}

function CREATE_STATE_ICON({ state }: { state: string }) {
  if (state === "confirme") {
    return (
      <FontAwesomeIcon icon={faCircleCheck} color="green" title="confirmé" />
    );
  } else if (state === "encours") {
    return (
      <FontAwesomeIcon
        icon={faHourglassStart}
        color="orange"
        title="en attente de validation  "
      />
    );
  } else if (state === "annule") {
    return <FontAwesomeIcon icon={faCircleXmark} color="red" title="annulé" />;
  } else {
    return (
      <FontAwesomeIcon icon={faQuestion} color="red" title="je sais pas" />
    );
  }
}
function GEN_RDV({ handleToggleEditform, Seteditform_rdv }: any) {
  const [rdvData, setRdvData] = useState([]);

  useEffect(() => {
    Load_rdv().then((data: any) => {
      setRdvData(data);
    });
  }, []);
  if (rdvData && rdvData.length > 0) {
    return (
      <>
        {rdvData.map((rdv: any, index) => (
          // <div className="row" key={index}>
          <div
            className={`row ${
              rdv.nom === "RESERVE" && rdv.prenom === "RESERVE" ? "red-row" : ""
            }`}
            key={index}
          >
            <div className="column">{rdv.nom}</div>
            <div className="column">{rdv.prenom}</div>
            <div className="column">
              {moment
                .unix(rdv.date_time._seconds)
                .locale("fr")
                .format("DD/MM/YYYY")}
            </div>
            <div className="column">
              {moment
                .unix(rdv.date_time._seconds)
                .utc()
                .locale("fr")
                .format("HH:mm")}
            </div>
            <div className="column">
              <GEN_DEMARCHE demarche={rdv.demarche} />
            </div>
            <div className="column">
              <Link
                target={"_blank"}
                to={
                  "/confirmer?rdv_id=" +
                  rdv.id +
                  "&code=" +
                  rdv.code_confirmation
                }
              >
                <CREATE_STATE_ICON state={rdv.statut} />
              </Link>
            </div>
            <div className="column">
              <Link
                target={"_blank"}
                to={
                  "/annuler?rdv_id=" + rdv.id + "&code=" + rdv.code_confirmation
                }
              >
                <FontAwesomeIcon
                  width={40}
                  height={40}
                  icon={faBan}
                  color="red"
                  title="annuler le rdv"
                  onClick={() => {
                    Cancel_Rdv(rdv.id).then(() => {});
                  }}
                />
              </Link>
            </div>
            <div className="column">
              {/* Seteditform_rdvid */}
              <FontAwesomeIcon
                icon={faSquarePen}
                color="black"
                title="modifier le rdv"
                onClick={() => {
                  handleToggleEditform();
                  Seteditform_rdv(rdv);
                }}
              />
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return (
      <>
        <center>
          <h1>Impossible de récuperer les rendez vous </h1>
        </center>
      </>
    );
  }
}

function GEN_RDV_TODAY({ handleToggleEditform, Seteditform_rdv }: any) {
  const [rdvData, setRdvData] = useState([]);

  useEffect(() => {
    Load_rdv_today().then((data: any) => {
      setRdvData(data);
    });
  }, []);
  if (rdvData && rdvData.length > 0) {
    return (
      <>
        {rdvData.map((rdv: any, index) => (
          // <div className="row" key={index}>
          <div
            className={`row ${
              rdv.nom === "RESERVE" && rdv.prenom === "RESERVE" ? "red-row" : ""
            }`}
            key={index}
          >
            <div className="column">{rdv.nom}</div>
            <div className="column">{rdv.prenom}</div>
            <div className="column">
              {moment
                .unix(rdv.date_time._seconds)
                .locale("fr")
                .format("DD/MM/YYYY")}
            </div>
            <div className="column">
              {moment
                .unix(rdv.date_time._seconds)
                .utc()
                .locale("fr")
                .format("HH:mm")}
            </div>
            <div className="column">
              <GEN_DEMARCHE demarche={rdv.demarche} />
            </div>
            <div className="column">
              <Link
                target={"_blank"}
                to={
                  "/confirmer?rdv_id=" +
                  rdv.id +
                  "&code=" +
                  rdv.code_confirmation
                }
              >
                <CREATE_STATE_ICON state={rdv.statut} />
              </Link>
            </div>
            <div className="column">
              <Link
                target={"_blank"}
                to={
                  "/annuler?rdv_id=" + rdv.id + "&code=" + rdv.code_confirmation
                }
              >
                <FontAwesomeIcon
                  width={40}
                  height={40}
                  icon={faBan}
                  color="red"
                  title="annuler le rdv"
                  onClick={() => {
                    Cancel_Rdv(rdv.id).then(() => {});
                  }}
                />
              </Link>
            </div>
            <div className="column">
              {/* Seteditform_rdvid */}
              <FontAwesomeIcon
                icon={faSquarePen}
                color="black"
                title="modifier le rdv"
                onClick={() => {
                  handleToggleEditform();
                  Seteditform_rdv(rdv);
                }}
              />
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return (
      <>
        <center>
          <h1>Impossible de récuperer les rendez vous </h1>
        </center>
      </>
    );
  }
}
function GEN_DEMARCHE({ demarche }: { demarche: string }) {
  if (demarche === "first_idcard") {
    return <>première demande de pièce d'identité</>;
  } else if (demarche === "renew_idcard") {
    return <>demande renouvellement de pièce d'identité</>;
  } else if (demarche === "renew_passport") {
    return <>demande renouvellement de passeport</>;
  } else if (demarche === "first_passport") {
    return <>première demande de passeport</>;
  } else {
    return <>Demarche inconnu</>;
  }
}

function Block_date(e: React.FormEvent<HTMLFormElement>) {
  e.preventDefault();
  var date = (e.currentTarget.elements.namedItem("date") as HTMLInputElement)
    .value;
  console.log(date);
  block_creaneau(date).then((data: any) => {
    console.log(data);
    if (data?.error === "rdv_exist") {
      alert("Oups des rendez-vous sont déjà pris pour cette date");
    } else if (data?.sucess === "date déja à jour") {
      alert("date déja bloqué");
    } else {
      alert("impossible de mettre à jour la date");
    }
  });
}

async function Cancel_Rdv(rdv_id: any) {
  if (rdv_id) {
    var answer = window.confirm("voulez vous annuler ce rendez-vous ?");
    if (answer) {
      console.log(rdv_id);
      console.log("ok je vais annuler le rdv");
    } else {
      console.log("rendez vous non annulé");
    }
  }
}

interface GenTimeListProps {
  Localcreneaux: Array<any>;
}
function GenTimeList({ Localcreneaux }: GenTimeListProps): JSX.Element {
  if (Localcreneaux && Localcreneaux.length > 0) {
    const creneauxList = Localcreneaux.map(function (element: any) {
      return (
        <option key={element} value={element}>
          {element}
        </option>
      );
      // `${element.firstName} ${element.lastName}`;
    });

    return <>{creneauxList}</>;
  } else {
    return (
      <>
        <option value="">pas de créneaux disponible</option>
      </>
    );
  }
}

async function edit_rdv_srv(e: any) {
  e.preventDefault();
  var date = (e.currentTarget.elements.namedItem("date") as HTMLInputElement)
    .value;
  var heure = (e.currentTarget.elements.namedItem("heure") as HTMLInputElement)
    .value;
  var rdv_id = (
    e.currentTarget.elements.namedItem("id_rdv") as HTMLInputElement
  ).value;
  var bloquer_ancien_creneau = (
    e.currentTarget.elements.namedItem(
      "bloquer_ancien_creneau"
    ) as HTMLInputElement
  ).checked;
  if (date && heure && rdv_id && typeof bloquer_ancien_creneau == "boolean") {
    console.log(bloquer_ancien_creneau);

    edit_rdv(date, heure, rdv_id, bloquer_ancien_creneau).then((data: any) => {
      console.log(data);
      if (data.succes) {
        alert("modification à bien été prise en compte");
      } else if (data.error) {
        alert(data.error);
      } else {
        alert("une erreur imprévue s'est produite ");
      }
    });
  } else {
    alert("merci de bien voloir remplir les champs");
  }
  console.log(date);
  console.log(heure);
}
