import config from "../../../config";
export async function Load_rdv(): Promise<string | undefined> {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      uuid: localStorage.getItem("admin_uuid"),
      token: localStorage.getItem("admin_token"),
    }),
  };

  var mareponse;
  try {
    await fetch(config.url + "/api/admin/listallrdv", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          mareponse = data;
        } else {
          mareponse = "error";
        }
      });
  } catch (error) {
    mareponse = "error";
  }

  return mareponse;
}

export async function Load_rdv_today(): Promise<string | undefined> {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      uuid: localStorage.getItem("admin_uuid"),
      token: localStorage.getItem("admin_token"),
    }),
  };

  var mareponse;
  try {
    await fetch(config.url + "/api/admin/listallrdv_today", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          mareponse = data;
        } else {
          mareponse = "error";
        }
      });
  } catch (error) {
    mareponse = "error";
  }

  return mareponse;
}
