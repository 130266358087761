import { Link } from "react-router-dom";
import styled from "styled-components";

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3c6cb3;
  width: 100%;
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  li {
    margin: 5px;
    font-size: 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: black;
  }
  li:hover {
    color: #193142;
  }
  a {
    color: black;
    text-decoration: none;
  }
`;
export default function MyFooter() {
  return (
    <Footer>
      <ul>
        <Link to="/">
          <li>Accueil</li>
        </Link>
        <Link to="/contact">
          <li>Contact</li>
        </Link>
        <Link to="/reservation">
          <li>Prise de rendez vous</li>
        </Link>
        <Link to="/cgu">
          <li>CGU</li>
        </Link>
      </ul>
    </Footer>
  );
}
