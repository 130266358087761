import styled from "styled-components";
import { useLottie } from "lottie-react";
import done from "../../../assets/lootie/done_1.json";
import { NavLink as Link } from "react-router-dom";
const Dive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  h2.email_send_title {
    text-align: center;
    padding-top: 40px;
  }
  .rdv_btn {
    padding: 10px;
    margin: 5px;
    border: none;
    background-color: #3c6cb3;
    color: white;
    border-radius: 20px;
  }
`;
const Done = () => {
  const options = {
    animationData: done,
    loop: false,
  };
  const { View } = useLottie(options);

  return (
    <Dive className="yo">
      {View}
      <h2 className="email_send_title">
        Merci <br />
        Votre rendez-vous est annulé
      </h2>
      <p>Vous serez notifié par e-mail.</p>
      <Link to="/reservation">
        <button className="rdv_btn">Prendre un nouveau RDV</button>
      </Link>
    </Dive>
  );
};

export default Done;
