import config from "../../../config";
async function block_creaneau(date: any): Promise<string | undefined> {
  let parts = date && date.split("-");
  let convertedDate = parts && parts[2] + "/" + parts[1] + "/" + parts[0];

  if (convertedDate && convertedDate.length > 0) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uuid: localStorage.getItem("admin_uuid"),
        token: localStorage.getItem("admin_token"),
        jour: convertedDate,
      }),
    };

    var mareponse;
    try {
      await fetch(config.url + "/api/admin/block_creneau_safe", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data) {
            mareponse = data;
          } else {
            mareponse = "error";
          }
        });
    } catch (error) {
      mareponse = "error";
    }
  }
  return mareponse;
}

async function block_creaneau_multi(
  date: any,
  creneaux: any
): Promise<string | undefined> {
  let parts = date && date.split("-");
  let convertedDate = parts && parts[2] + "/" + parts[1] + "/" + parts[0];

  if (convertedDate && convertedDate.length > 0) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uuid: localStorage.getItem("admin_uuid"),
        token: localStorage.getItem("admin_token"),
        jour: convertedDate,
        creneaux: creneaux,
      }),
    };

    var mareponse;
    try {
      await fetch(
        config.url + "/api/admin/block_creneau_safe_multi",
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          if (data) {
            mareponse = data;
          } else {
            mareponse = "error";
          }
        });
    } catch (error) {
      mareponse = "error";
    }
  }
  return mareponse;
}

export { block_creaneau, block_creaneau_multi };
