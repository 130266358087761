import styled from "styled-components";
import { useLottie } from "lottie-react";
import done from "../../../assets/lootie/loading_1.json";

const Dive = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
width:100%;
margin-top:4rem;
h2.email_send_title
{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:20rem;
text-align:center;
}

`;
type Messages = {
    [key: number]: string;
  };
const Loading = (propos:  { message: number }) => {

    console.log(propos.message);
  const options = {
    animationData: done,
    loop: true
  };

  const { View } = useLottie(options);
  var messages:Messages = {
    1: "chargement veuillez patienter",
    2 : "oops le serveur ne répond pas",
  };

  return <Dive className="yo">{View}
  <h2 className="email_send_title">{messages[propos.message]} </h2>
  </Dive>;
};

export default Loading;