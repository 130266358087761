import config from "../../config";
export async function Contact_form(
  email: string,
  nom: string,
  prenom: string,
  message: string,
  captcha_key: string
): Promise<string | undefined> {
  if (
    email &&
    nom &&
    prenom &&
    email.length > 0 &&
    nom.length > 0 &&
    prenom.length > 0 &&
    captcha_key.length > 0
  ) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        nom: nom,
        prenom: prenom,
        message: message,
        captcha: captcha_key,
      }),
    };

    var mareponse;
    try {
      await fetch(config.url + "/api/autre/contact_form", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            mareponse = data;
          } else {
            mareponse = "error";
          }
        });
    } catch (error) {
      mareponse = "error";
    }
  }
  return mareponse;
}
