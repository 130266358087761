// import Done from "../../components/animation/Done";
import Error from "../../components/animation/Error";
import Loading from "../../components/animation/Loading";
import { useState, useEffect, useRef } from "react";
import config from "../../config";
import Cancel from "../../components/animation/Cancel";

function Confirm() {
  const [code, setCode] = useState<string | null>(null);
  const [rdv_id, setRdv_id] = useState<string | null>(null);
  const [confirme, setConfirme] = useState<boolean | undefined>(false);
  const [error, setError] = useState<number | undefined>();
  const init = useRef(false);
  useEffect(() => {
    if (!init.current) {
      init.current = true;
      const urlparms = new URLSearchParams(window.location.search);
      var code = urlparms.get("code");
      var rdv_id = urlparms.get("rdv_id");
      setCode(code);
      setRdv_id(rdv_id);

      if (code && rdv_id) {
        Confirm_rdv(code, rdv_id)
          .then((data) => {
            console.log(data.error);
            if (data.error) {
              if (data.error === "votre rendez-vous est déjà annulé") {
                setError(1);
              } else if (
                data.error === "impossible de confirmer votre rendez-vous"
              ) {
                setError(2);
              } else if (data.error === "le code d annulation est incorrect") {
                setError(4);
              }
              setConfirme(false);
            } else if (data.success) {
              setConfirme(true);
            } else {
              setError(2);
              setConfirme(false);
            }
          })
          .catch((error) => {
            setConfirme(undefined);
          });
      }
    }
  }, []);

  if (code === null || rdv_id === null) {
    return <Error message={4} />;
  } else if (code && rdv_id && confirme === true) {
    return <Cancel />;
  } else if (error === 1) {
    return <Error message={5} />;
  } else if (error === 2) {
    return <Error message={1} />;
  } else if (error === 4) {
    return <Error message={4} />;
  } else {
    return <Loading message={1} />;
  }
}
async function Confirm_rdv(code: string, rdv_id: string) {
  const response = await fetch(config.url + "/api/rendez-vous/cancel_rdv", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ code: code, rdv_id: rdv_id }),
  });

  const data = await response.json();
  console.log(data);
  return data;
}
export default Confirm;
