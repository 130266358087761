import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./assets/styles/home.module.css";

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Calendar from "./pages/Calendar";
import Reservation from "./pages/Reservation/index";
import Upload from "./pages/Upload";
import Cgu from "./pages/Cgu";
import Menu from "./components/Menu";
import Footer from "./components/Footer";
import Nomatch from "./pages/Nomatch";
// admin
import Croadmin from "./pages/Croadmin";
import CROADMIN_DASH from "./pages/Croadmin/Dashboard";
import CROADMIN_PROFILE from "./pages/Croadmin/Profile";
import CROADMIN_DECONNECTER from "./pages/Croadmin/Deconnecter";
// admin
import Confirm from "./pages/Confirm";
import ADMIN_MENU from "./components/Menu/admin";
import ANNULER from "./pages/Cancel";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const footer = false;
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Menu active="home" />
              <Home />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Menu />
              <Contact />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="/reservation"
          element={
            <>
              <Menu />
              <Reservation />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="/upload"
          element={
            <>
              <Menu />
              <Upload />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="/cgu"
          element={
            <>
              <Menu />
              <Cgu />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="/croadmin"
          element={
            <>
              <Menu active="home" />
              <Croadmin />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route path="/yup" element={<Calendar />} />
        <Route
          path="/confirmer"
          element={
            <>
              <Menu active="home" />
              <Confirm />
              {footer ? <Footer /> : ""}
            </>
          }
        ></Route>
        <Route
          path="/annuler"
          element={
            <>
              <Menu active="home" />
              <ANNULER />
              {footer ? <Footer /> : ""}
            </>
          }
        ></Route>
        <Route
          path="/croadmin/dashboard"
          element={
            <>
              {/* <Menu active="home" /> */}
              <ADMIN_MENU />
              <CROADMIN_DASH />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="/croadmin/profile"
          element={
            <>
              {/* <Menu active="home" /> */}
              <ADMIN_MENU />
              <CROADMIN_PROFILE />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="/croadmin/deconnecter"
          element={
            <>
              {/* <Menu active="home" /> */}
              <ADMIN_MENU />
              <CROADMIN_DECONNECTER />
              {footer ? <Footer /> : ""}
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <Menu />
              <Nomatch />
              {footer ? <Footer /> : ""}
            </>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
