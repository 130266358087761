import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

import AdminLoginform from "./AdminLoginform";

export default function Crodmin() {
  const navigate = useNavigate();
  const [admin, setadmin] = useState<any>();

  useEffect(() => {
    const admin_uuid: any = localStorage.getItem("admin_uuid");
    const admin_token: any = localStorage.getItem("admin_token");
    const decodedToken: any = admin_token && jwtDecode(admin_token);
    // Get the expiration time from the decoded token
    const expirationTime = admin_token && decodedToken.exp;

    // Get the current timestamp
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (expirationTime < currentTimestamp) {
      localStorage.removeItem("admin_uuid");
      localStorage.removeItem("admin_token");
    } else {
      if (
        admin_uuid &&
        admin_token &&
        admin_uuid !== "" &&
        admin_token !== "" &&
        admin_uuid !== null &&
        admin_token !== null &&
        admin_uuid !== undefined &&
        admin_token !== undefined
      ) {
        navigate("/croadmin/dashboard");
        setadmin(true);
      } else {
        setadmin(false);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (admin) {
      navigate("/croadmin/dashboard");
    }
  }, [admin, navigate]);

  if (!admin) {
    return <AdminLoginform login={admin} onChange={setadmin} />;
  } else {
    return <></>;
  }
}
