// import { Link } from "react-router-dom";
import { NavLink as Linke } from "react-router-dom";
import styled from "styled-components";

const Header = styled.header`
  nav {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    background-color: #3c6cb3;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  li {
    margin: 5px;
    font-size: 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: white;
  }
  li:hover {
    color: #193142;
  }
  .crosnelogo {
    width: 250px;
    height: 100px;
    border-radius: 10px;
    margin: 5px;
  }
  a {
    color: black;
    text-decoration: none;
  }
  @media only screen and (max-width: 820px) {
    ul {
      display: none;
    }
  }
`;

const Link = styled(Linke)`
  &.active {
    background-color: transparent;
    color: red;
  }
`;

export default function Menu(active: any) {
  return (
    <Header>
      <nav>
        <Link to="/">
          <img
            className="crosnelogo"
            src="https://www.crosne.fr/templates/img/crosne_txt.png"
            alt="logo ville de crosne"
          />
        </Link>
        <ul>
          <Link to="/croadmin/dashboard">
            <li>LES RENDEZ-VOUS</li>
          </Link>
          <Link to="/croadmin/profile">
            <li>MON PROFIL</li>
          </Link>
          <Link to="/croadmin/deconnecter">
            <li>SE DÉCONNECTER</li>
          </Link>
        </ul>
      </nav>
    </Header>
  );
}
