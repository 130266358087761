import * as firebase from "firebase/app";
import {
  getAuth,
  connectAuthEmulator,
  onAuthStateChanged,
  getIdTokenResult,
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";

import config from "../../../config";

export default async function Login(
  email: string,
  password: string,
  cb: (uuid: string | null, token: string | null) => void
) {
  try {
    const firebaseConfig = {
      apiKey: "AIzaSyD1Ud1dqPZbSkadNdbMdOv5FUogy0hc7IQ",
      authDomain: "crosne-rendez-vous.firebaseapp.com",
      projectId: "crosne-rendez-vous",
      storageBucket: "crosne-rendez-vous.appspot.com",
      messagingSenderId: "406030387461",
      appId: "1:406030387461:web:d463a34faffb4a8d568f6c",
      measurementId: "G-1DWJ1GQ82F",
    };
    const app = firebase.initializeApp(firebaseConfig);
    const auth = getAuth(app);

    if (config.environment === "development") {
      (auth as unknown as any)._canInitEmulator = true;
      connectAuthEmulator(auth, "http://127.0.0.1:9099", {
        disableWarnings: true,
      });
    }

    // await setPersistence(auth, browserSessionPersistence); // Set token persistence

    // await signInWithEmailAndPassword(auth, email, password);
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, email, password);
      })
      .catch((error) => {
        console.log(error);
      });

    // onAuthStateChanged(auth, (user: any) => {
    //   getIdTokenResult(user as any, false)
    //     .then((data: any) => {
    //       if (user) {
    //         cb(user.uid, data.token);
    //       } else {
    //         cb(null, null);
    //       }
    //     })
    //     .catch((e) => {
    //       return cb(null, null);
    //     });
    // });

    onAuthStateChanged(auth, (user: any) => {
      getIdTokenResult(user as any, false)
        .then((data: any) => {
          if (user) {
            // Set the token expiration time to 24 hours (in milliseconds)
            const expiresIn = 60 * 60 * 24 * 1000; // 24 hours
            const now = Date.now();
            const expirationTime = now + expiresIn;

            auth.currentUser?.getIdToken(true).then((refreshedToken) => {
              cb(user.uid, refreshedToken);
              setTimeout(() => {
                // Refresh the token when it expires after 24 hours
                auth.currentUser?.getIdToken(true).then((newToken) => {
                  cb(user.uid, newToken);
                });
              }, expirationTime - now);
            });
          } else {
            cb(null, null);
          }
        })
        .catch((e) => {
          return cb(null, null);
        });
    });
  } catch (err) {
    console.log("error to get data" + err);
    cb(null, null);
  }
}
