import styled from "styled-components";
const Div = styled.div`
width: 80%;
margin: auto;
max-width: 80%;
h1,h2
{
  text-align:center;
}
p
{
  text-align: justify;
}
.text_container
{
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}
.text_sub_container
{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  max-width:1000px;
}
.header_title
{
  margin-top: 20px;
}
`;
export default function Cgu() {

  return (
    // <Div>
    //   <h1>Conditions Générales d'Utilisation</h1>
    //   <p>
    //     Bienvenue sur notre site de réservation de rendez-vous. Notre site est
    //     fourni par le service Firebase de Google et propose une authentification
    //     anonyme pour la sécurité et la confidentialité de vos données. En
    //     accédant et en utilisant ce site, vous acceptez les présentes conditions
    //     générales d'utilisation. Veuillez lire attentivement les termes
    //     ci-dessous et assurez-vous que vous les comprenez avant d'utiliser ce
    //     site.
    //   </p>
    //   <h2>1. Déclaration de confidentialité</h2>
    //   <p>
    //     Notre site s'engage à protéger la confidentialité de vos données
    //     personnelles. Nous prenons des mesures pour protéger vos informations et
    //     veiller à ce qu'elles ne soient pas divulguées à des tiers. Toutes les
    //     données personnelles que vous fournissez sont stockées et traitées de
    //     manière sécurisée.
    //   </p>
    //   <h2>2. Utilisation des données</h2>
    //   <p>
    //     Nous ne collectons que des données nécessaires à la fourniture des
    //     services proposés sur notre site. Les données que vous nous fournissez
    //     sont utilisées uniquement pour fournir des services et des produits, et
    //     nous ne les partagerons pas avec des tiers.
    //   </p>
    //   <h2>3. Protection des données</h2>
    //   <p>
    //     Nous utilisons des technologies de sécurité pour protéger vos données.
    //     Toutes les données sont stockées dans des bases de données sécurisées et
    //     nous prenons des mesures pour empêcher tout accès non autorisé ou toute
    //     modification ou divulgation de vos données.
    //   </p>
    //   <h2>4. Cookies</h2>
    //   <p>
    //     Nous utilisons des cookies pour faciliter votre expérience sur notre
    //     site. Les cookies sont des fichiers qui sont stockés sur votre
    //     ordinateur et qui nous permettent de mémoriser certaines informations.
    //     Les cookies nous permettent d'améliorer nos services et nous n'utilisons
    //     pas ces informations à d'autres fins.
    //   </p>
    //   <h2>5. Modifications des conditions générales d'utilisation</h2>
    //   <p>
    //     Nous nous réservons le droit de modifier les présentes conditions
    //     générales d'utilisation à tout moment et sans préavis. Nous vous
    //     invitons à consulter régulièrement cette page pour vous assurer que vous
    //     êtes informé des modifications apportées.
    //   </p>
    //   <h2>6. Droit applicable</h2>
    //   <p>
    //     Les présentes conditions générales d'utilisation sont régies et
    //     interprétées conformément aux lois de la République française. Tout
    //     litige relatif à ces conditions générales d'utilisation sera soumis à la
    //     juridiction exclusive des tribunaux de la République française.
    //   </p>
    //   <p>
    //     En utilisant notre site, vous acceptez les présentes conditions
    //     générales d'utilisation. Si vous avez des questions, n'hésitez pas à
    //     nous contacter.
    //   </p>
    //   <p>
    //     Date d'entrée en vigueur : 26/01/2023
    //     <br />
    //     Dernière mise à jour : 26/01/2023
    //   </p>
    // </Div>
    <Div>
      <h1 className='header_title'>Conditions Générales d'Utilisation</h1>
<div className="text_container">
  <div className="text_sub_container">
<h2 className="subtitle_1">1. Collecte et utilisation des données</h2>
<p>Nous collectons certaines informations personnelles vous concernant afin de vous fournir nos services de réservation de rendez-vous. Les informations collectées peuvent inclure votre nom, votre adresse e-mail, votre numéro de téléphone, ainsi que toute autre information nécessaire à la réservation et à la fourniture de nos services. Nous utilisons ces informations uniquement dans le cadre de la réservation de rendez-vous et pour vous contacter en lien avec vos réservations.</p>

<h2>2. Stockage des données</h2>
<p>Nous utilisons le service Firebase de Google pour stocker et gérer les informations collectées. Firebase offre des mesures de sécurité avancées pour protéger vos données et garantir leur confidentialité. Nous prenons des précautions raisonnables pour nous assurer que vos informations sont sécurisées. Toutefois, veuillez noter qu'aucune méthode de transmission ou de stockage électronique n'est totalement sécurisée et nous ne pouvons garantir la sécurité absolue de vos données.</p>

<h2>3. Cookies et technologies similaires</h2>
<p>Nous utilisons des cookies et d'autres technologies similaires pour améliorer votre expérience sur notre site. Les cookies sont de petits fichiers texte qui sont stockés sur votre appareil et qui nous permettent de collecter des informations telles que votre adresse IP, le type de navigateur que vous utilisez, les pages que vous consultez, etc. Ces informations sont utilisées à des fins d'analyse et pour personnaliser votre expérience de réservation. Vous avez la possibilité de désactiver les cookies dans les paramètres de votre navigateur, mais veuillez noter que cela pourrait limiter certaines fonctionnalités de notre site.</p>

<h2>4. Confidentialité et sécurité</h2>
<p>Nous nous engageons à protéger la confidentialité de vos informations personnelles. Nous prenons des mesures techniques et organisationnelles appropriées pour sécuriser vos données et empêcher tout accès non autorisé, toute divulgation ou toute perte de données. Cependant, veuillez noter qu'Internet n'est pas un environnement totalement sécurisé et nous ne pouvons garantir la sécurité absolue de vos données lors de leur transmission via Internet.</p>

<h2>5. Modification des CGU</h2>
<p>Nous nous réservons le droit de modifier les présentes CGU à tout moment et sans préavis. Toute modification sera effective dès sa publication sur notre site. Il est de votre responsabilité de consulter régulièrement les CGU pour prendre connaissance des éventuelles modifications. En continuant à utiliser notre site après la publication des modifications, vous acceptez les CGU modifiées.</p>

<h2>6. Droit applicable et juridiction compétente</h2>
<p>Les présentes CGU sont régies et interprétées conformément aux lois de la République française. Tout litige découlant de l'utilisation de notre site ou lié aux présentes CGU sera soumis à la compétence exclusive des tribunaux français.</p>

<p>Si vous avez des questions ou des préoccupations concernant nos CGU, veuillez nous contacter à l'adresse fournie sur notre site.</p>

<p>Date d'entrée en vigueur : 01/06/2023</p>
</div>
</div>
      </Div>
  );
}
