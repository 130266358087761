import styled from "styled-components";
import { Link } from "react-router-dom";
// import mairie_de_crosne from '../../assets/img/mairie_de_crosne-min.png';
import mairie_de_crosne from '../../assets/img/Papiers-didentite.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation} from '@fortawesome/free-solid-svg-icons'
const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align:center;
  h1 {
    font-size: 2rem;
    text-transform: uppercase;
  }
  h2, li
  {
    font-size: 1.5rem;
  }
  .btn_prendre_rdv {
    align-self: center;
    background-color: #fff;
    background-image: none;
    background-position: 0 90%;
    background-repeat: repeat no-repeat;
    background-size: 4px 3px;
    border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
    border-style: solid;
    border-width: 2px;
    box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 15px 28px 25px -18px;
    box-sizing: border-box;
    color: #41403e;
    cursor: pointer;
    display: inline-block;
    font-family: Neucha, sans-serif;
    font-size: 1rem;
    line-height: 23px;
    outline: none;
    padding: 0.75rem;
    text-decoration: none;
    transition: all 235ms ease-in-out;
    border-bottom-left-radius: 15px 255px;
    border-bottom-right-radius: 225px 15px;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin:5px;

  }
  @supports (-webkit-overflow-scrolling: touch) {

      .btn_prendre_rdv {
        -webkit-border-radius: 15px;
        -webkit-border-width: 2px;
        -webkit-border-style: solid;
        border-width: 2px;
        border-style: solid;
        border-color: #000; /* Remplacez par la couleur souhaitée */
        border-radius: 15px;
      }
    
  }
  .btn_prendre_rdv:focus {
    -webkit-box-shadow:rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 4px -6px;
  }

  .btn_prendre_rdv:hover {
    -webkit-box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
    box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px -5px;
    transform: translate3d(0, 2px, 0);
  }

  .btn_prendre_rdv :active {
    -webkit-box-shadow:#3c4fe0 0 3px 7px inset;
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  p
  {
    text-align:center;
  }
  .photo_mairie
  {
    height:auto;
    max-width:100%;	
    width:1000px;
    border-radius:10px;
    object-fit:contain;
  }
  .titre_bienvenue
  {
    margin-top:20px;
  }
  .message_box_container
  {
    margin:10px;
    font-size: 1.5rem;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
  }
  .message_box
  {
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    width:50%;
    background-color:#f1f1f1;
    border-radius:10px;
    padding:5px;
    text-transform:uppercase;
  }
  @media screen and (max-width: 970px) {
    .message_box
    {
      display:flex;
      align-items:center;
      justify-content:center;
      flex-direction:column;
      width:80%;
      background-color:#f1f1f1;
      border-radius:10px;
      padding:5px;
    }
  }
`;

function Home() {
  return (
    <>
          <Div>
        <h1 className="titre_bienvenue">Bienvenue sur la page de prise de rendez-vous ! </h1>
        <Link to="/reservation"><img className="photo_mairie" src={mairie_de_crosne} alt="mairie de crosne"/></Link>
        <h2>La Mairie de Crosne vous accueille pour les démarches suivantes:</h2> 
        <ul>
          <li>demande de renouvellement de passeport</li>
          <li>demande de renouvellement de Carte nationale d'identité</li>
          <li>première demande de Carte nationale d'identité</li>
          <li>première demande de passeport</li>
        </ul> 
        <div className="message_box_container">
          <div className="message_box">
            <div>
              <p><FontAwesomeIcon icon={faTriangleExclamation}/> Attention</p>
            <p>
              un créneau = un rendez-vous et une seule démarche. <br/>Si vous souhaitez prendre
              plusieurs rendez-vous, il vous faudra répéter l'opération autant
              de fois que nécessaire.
            </p>
          </div>
        </div>
        </div>
        <Link to="/reservation">
          <button className="btn_prendre_rdv">Prendre un rendez vous</button>
        </Link>
      </Div>
    </>
  );
}

export default Home;
