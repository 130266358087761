import styled from "styled-components";
import { useLottie } from "lottie-react";
import done from "../../../assets/lootie/error.json";

const Dive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 4rem;
  h2.email_send_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20rem;
    text-align: center;
  }
`;
type Messages = {
  [key: number]: string;
};
const Done = (propos: { message: number }) => {
  console.log(propos.message);
  const options = {
    animationData: done,
    loop: true,
  };

  const { View } = useLottie(options);
  var messages: Messages = {
    1: "Votre code n'est pas valable Merci de bien vouloir valider le rendez-vous en cliquant sur le lien de confirmation reçu par email",
    2: "Une erreur imprévu s'est produite",
    3: "Votre rendez-vous est déjà confirmé",
    4: "Votre code n'est pas valable Merci de bien vouloir annuler le rendez-vous en cliquant sur le lien d'annulation reçu par email",
    5: "Votre rendez-vous est déjà annulé.",
  };

  return (
    <Dive className="yo">
      {View}
      <h2 className="email_send_title">{messages[propos.message]} </h2>
    </Dive>
  );
};

export default Done;
