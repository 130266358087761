// import React from "react";

// // import { format } from "date-fns";
// import { DayPicker } from "react-day-picker";
// import "react-day-picker/dist/style.css";

// export default function Example() {
//   const [selected, setSelected] = React.useState<Date>();

//   // let footer = <p>Please pick a day.</p>;
//   // if (selected) {
//   //   footer = <p>You picked {format(selected, "PP")}.</p>;
//   // }
//   const modifiers = {
//     weekend: {
//       daysOfWeek: [0, 6]
//     }
//   };
//   return (
//     <DayPicker
//       mode="single"
//       selected={selected}
//       onSelect={setSelected}
//       modifiers={modifiers}
//       disabledDays={modifiers.weekend}
//     />
//   );
// }

export default function test()
{
  return <><h1>Hi</h1></>;
}