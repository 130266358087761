import styled from "styled-components";

const Input = styled.input`
  padding: 20px;
  background-color: red;
  ::file-selector-button {
    padding: 40px 100px 40px 100px;
  }
`;

function handleInputFile(file: any) {
  console.log(file);
  console.log("hi");
}
function HandleDragOver() {
  console.log("Yup");
}

function Drop() {
  return (
    <>
      <h1>Upload</h1>
      <form>
        <label>Choose a profile picture:</label>
        <Input
          onDragOver={HandleDragOver}
          onDrop={handleInputFile}
          type="file"
          accept="image/png, image/jpeg"
        />
      </form>
    </>
  );
}

export default Drop;
