import { useLocation } from "react-router-dom";
import styled from "styled-components";
export default function NoMatch() {
  let location = useLocation();
  const Div = styled.div`
  display:flex;
  align-items:center;
  flex-direction: column;
  justify-content:center;
    img {
      width: 50rem;
      max-width: 70%;
    }
  `;
  return (
    <Div>
        <h3>Page non trouvée</h3>
        <h3>
          <code>{location.pathname}</code>
        </h3>
        <img
          alt="erreur 404"
          src="https://firebasestorage.googleapis.com/v0/b/crosne-rendez-vous.appspot.com/o/2104.i301.009.F.m004.c9.404%20error%20isometric%20background-min.jpg?alt=media&token=7f5d7393-6b58-4cc6-a778-139eae2cef05&_gl=1*gwio7g*_ga*MjA4NDE2NDc3OC4xNjcwNDIyODM0*_ga_CW55HF8NVT*MTY4NjA0MDUzOC45Ny4xLjE2ODYwNDA2MTguMC4wLjA."/>
    </Div>
  );
}
