import React, { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    // Redirect to Google.com
    window.location.href = "https://rdv.anct.gouv.fr/org/102/crosne";
  }, []);

  return null; // You can return null or any other content you want here
}
