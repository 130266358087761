import config from "../../../config";
async function edit_rdv(
  date: any,
  heure: any,
  rdv_id: string,
  bloquer_ancien_creneau: boolean
): Promise<string | undefined> {
  console.log(date);
  console.log(heure);
  console.log(rdv_id);
  let parts = date && date.split("-");
  let convertedDate = parts && parts[2] + "/" + parts[1] + "/" + parts[0];

  if (convertedDate && convertedDate.length > 0) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        uuid: localStorage.getItem("admin_uuid"),
        token: localStorage.getItem("admin_token"),
        rdv_id: rdv_id,
        date: convertedDate,
        heure: heure,
        bloquer_ancien: bloquer_ancien_creneau,
      }),
    };

    var mareponse;
    try {
      await fetch(config.url + "/api/admin/edit_rdv", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            mareponse = data;
          } else {
            mareponse = "error";
          }
        });
    } catch (error) {
      mareponse = "error";
    }
  }
  return mareponse;
}

export { edit_rdv };
